<template>
  <div :class="{ 'tile is-parent': isTiled}">
    <div class="float-button-right">
      <div class="buttons">
        <button
          class="button tooltip is-tooltip-topright"
          @click="remove"
          data-tooltip="Delete contact">
          <span class="icon has-text-danger">
            <i class="mdi mdi-delete mdi-18px" />
          </span>
        </button>
        <button
          class="button tooltip is-tooltip-topright"
          @click="backToListing"
          data-tooltip="Back to contact listing">
          <span class="icon has-text-primary">
            <i class="mdi mdi-chevron-double-left mdi-18px" />
          </span>
        </button>
      </div>
    </div>
    <article
      :class="{ 'tile is-child box detail-page-tile' : isTiled }"
      v-if="contact">
      <tabs
        animation="slide"
        :only-fade="false"
        ref="tabs">
        <tab-pane label="Details">
          <contact-detail
            ref="contactDetail"
            :entity="contact"
            :v="v" />
        </tab-pane>
        <tab-pane
          label="Addresses"
          :icon="hasErrors(v.contact.addresses.$error)">
          <contact-address
            ref="contactAddress"
            :entity="contact"
            :v="v.contact"
            :is-tiled="false" />
        </tab-pane>
        <tab-pane
          label="Phones / Emails"
          :icon="hasErrors(v.contact.phones.$error || v.contact.emails.$error)">
          <contact-phone-email
            ref="contactPhoneEmail"
            :entity="contact"
            :v="v.contact"
            :is-tiled="false" />
        </tab-pane>
        <tab-pane label="Remarks">
          <contact-remark :entity="contact" />
        </tab-pane>
      </tabs>
    </article>
    <!-- <pre class="small">
      {{ v }}
    </pre> -->
  </div>
</template>
<script>
import { Tabs, TabPane } from '@/components/BulmaTabs'
import ContactDetail from './ContactDetail'
import ContactAddress from '@/views/contact/ContactAddress'
import ContactPhoneEmail from '@/views/contact/ContactPhoneEmail'
import ContactRemark from '@/views/contact/ContactRemark'

export default {
  components: {
    Tabs,
    TabPane,
    ContactDetail,
    ContactAddress,
    ContactPhoneEmail,
    ContactRemark
  },
  props: {
    contact: null,
    isTiled: {
      type: Boolean,
      default: false
    },
    v: null
  },
  computed: {},
  mounted() {
    this.v.$touch()
  },
  methods: {
    hasErrors(scope) {
      if (scope) {
        return 'mdi mdi-alert mdi-18px'
      }
      return null
    },
    backToListing() {
      if (this.v.$error || this.v.contact.addresses.$error || this.v.contact.emails.$error) {
        this.$notification.error('Validation', 'Please fix highlighted fields')
      } else {
        this.$emit('back')
      }
    },
    remove() {
      this.$emit('remove')
    }
  }
}
</script>
<style lang="scss" scoped>
.float-button-right {
  float: right;
  right: 30px;
  position: absolute;
  z-index: 15;
}
</style>
