<template>
  <div class="tile is-parent">
    <article class="tile is-child box detail-page-tile">
      <table class="table is-striped is-narrow is-fullwidth"
        v-if="selected === ''">
        <thead>
          <tr>
            <th>Title</th>
            <th>Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-for="(c, idx) in mappings"
            v-show="!c.isDeleted"
            :key="idx">
            <td>{{ c.contact.title }}</td>
            <td>
              <a @click="select(c.id)">{{ `${c.contact.firstName} ${c.contact.lastName}` }}</a>
            </td>
            <td>{{ c.contact.phones | defaultPhone }}</td>
            <td class="is-email">
              <div>{{ c.contact.emails | defaultEmail }}</div>
            </td>
            <td class="has-text-centered is-content-width">
              <a class="button is-small is-inverted is-gold"
                @click="setDefault(idx)">
                <span class="icon">
                  <i class="mdi mdi-24px"
                    :class="{'mdi-star': c.isDefault, 'mdi-star-outline':!c.isDefault}" />
                </span>
              </a>
              <a class="button is-small is-primary is-inverted"
                @click="select(c.id)">
                <span class="icon is-medium">
                  <i class="mdi mdi-lead-pencil mdi-24px" />
                </span>
              </a>
              <a class="button is-small is-danger is-inverted"
                @click="remove(idx)">
                <span class="icon is-medium">
                  <i class="mdi mdi-delete mdi-24px" />
                </span>
              </a>
            </td>
            <td class="is-content-width">
              <a class="button is-small is-danger is-inverted tooltip is-tooltip-topright is-tooltip-danger"
                @click="select(c.id)"
                v-if="$v.entity.contacts.$each[idx].$invalid"
                data-tooltip="Validation Errors">
                <span class="icon">
                  <i class="mdi mdi-alert mdi-24px" />
                </span>
                <!-- <span class="is-size-6 is-hidden-touch">Validation</span> -->
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <button class="button is-primary is-pulled-right"
        v-if="selected === ''"
        @click="addContact()">
        <span class="icon">
          <i class="mdi mdi-plus mdi-24px" />
        </span>
      </button>
      <!-- <pre>
                    {{$v}}
                  </pre> -->
      <contact-component-view v-if="selectedContact"
        :contact="selectedContact"
        :v="$v.entity.contacts.$each[selectedIndex]"
        @back="back"
        @remove="remove(selectedIndex)"
        @setDefault="setDefault(selectedIndex)" />
      <!-- <contact-component-view
        :contact="selectedContact"
        :v="$v.entity.contacts.$each[selectedIndex]"
        v-if="selectedContact"
        @back="back"
        @remove="remove(selectedIndex)"
        @setDefault="setDefault(selectedIndex)" /> -->
    </article>
  </div>
</template>
<script>
import ContactService from '@/views/contact/ContactService'
import ContactComponentView from './ContactComponentView'
import { EventHubTypes } from '@/enums'

export default {
  inject: ['$vv'],
  components: {
    ContactComponentView
  },
  filters: {
    defaultPhone(phones) {
      const phone = phones.find(p => p.isDefault && !p.isDeleted)
      return phone ? phone.no : ''
    },
    defaultEmail(emails) {
      const email = emails.find(e => e.isDefault && !e.isDeleted)
      return email ? email.address : ''
    }
  },
  props: {
    entity: null,
    assetType: String,
    detailRoute: String,
    assetId: String
  },
  data() {
    return {
      selected: '',
      selectedContact: null,
      selectedIndex: 0
    }
  },
  computed: {
    mappings: function() {
      if (this.entity && this.entity.contacts) {
        return this.entity.contacts //.filter(c => c.id === this.selected)
      } else {
        return []
      }
    },
    $v() {
      return this.$vv
    }
  },
  mounted() {
    this.$v.contactsGroup.$touch()
    this.$eventHub.$on(EventHubTypes.EntitySaved, () => {
      this.selected = ''
      this.selectedContact = null
    })
  },

  methods: {
    select(id) {
      this.selected = id
      this.selectedIndex = this.mappings.findIndex(m => m.id === id)
      this.selectedContact = this.mappings[this.selectedIndex].contact
    },

    addContact() {
      const assetId = this.assetId ? this.assetId : this.entity.id

      ContactService.getNewAssetContact(this.assetType, assetId).then(response => {
        const newMap = response.data

        var anyDefault = this.mappings.find(d => !d.isDeleted && d.isDefault)
        if (!anyDefault) {
          newMap.isDefault = true
        }

        this.mappings.push(newMap)

        this.selected = newMap.id
        this.selectedIndex = this.mappings.findIndex(m => m.id === this.selected)
        this.selectedContact = this.mappings[this.selectedIndex].contact
      })
    },

    remove(idx) {
      this.selected = ''
      this.selectedIndex = 0
      this.selectedContact = null

      if (this.mappings[idx].isNew) {
        this.mappings.splice(idx, 1)
      } else {
        this.mappings[idx].isDeleted = true
      }

      var anyDefault = this.mappings.find(d => !d.isDeleted && d.isDefault)
      var anyActive = this.mappings.find(d => !d.Deleted)

      if (!anyDefault && anyActive) {
        for (let i = 0; i < this.mappings.length; i++) {
          if (!this.mappings[i].isDeleted) {
            this.mappings[i].isDefault = true
            // this.selected = this.mappings[i].id
            break
          }
        }
      }
    },

    setDefault(idx) {
      for (let i = 0; i < this.mappings.length; i++) {
        this.mappings[i].isDefault = false
      }
      this.mappings[idx].isDefault = true
    },

    back() {
      this.selected = ''
      this.selectedIndex = 0
      this.selectedContact = null
    }
  },
  beforeRouteLeave: function(to, from, next) {
    next()
    // const message = 'Validation errors. Please fix before proceeding'
    // const title = 'Contacts'
    // if (!this.$v.contactsGroup.$error) {
    //   next()
    // } else {
    //   this.$router.replace(from.path)
    //   this.$notification.error(title, message)
    // }
  }
}
</script>
